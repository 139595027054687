module.exports = [{
      plugin: require('../../node_modules/gatsby-theme-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fefbf3","display":"minimal-ui","icon":"src/images/culdesac.png","name":"culdesac","short_name":"culdesac","start_url":"/","theme_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"398066ab25092303375854dfc1bff595"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xxs":"(max-width: 321px)","xs":"(max-width: 400px)","sm":"(max-width: 640px)","md":"(max-width: 768px)","lg":"(max-width: 1024px)","xl":"(max-width: 1280px)","xxl":"(max-width: 1400px)","3xl":"(max-width: 1600px)"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
